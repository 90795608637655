<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            User Log
        </h1>
        <div class="" v-if="item">
            <p>
                <div class="h-full w-full overflow-auto">
                    <JsonEditor
                        :options="{
                            confirmText: 'confirm',
                            cancelText: 'cancel',
                        }"
                        :objData="item" 
                        v-model="item" >
                    </JsonEditor>
                </div>
            </p>
            <p>
                Created On  {{getDate(item.created_at)}}
            </p>
            <p>
                Last Updated On  {{getDate(item.updated_at)}}
            </p>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'

export default {
    name: 'UserLog',
    mixins: [View],
    data: () => ({
        back: '/user-logs',
        parameter: 'log_id',
        action: '/userlog/',
    }),
    computed: {
    },
    props: [],
    methods: {
        getDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
