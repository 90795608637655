<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            User Logs
        </h1>
        <!--
        <LinkTemplate :to="{path:'/domains/create'}" class="my-6 ml-6">
            Add New Domain
        </LinkTemplate>
        -->
    </div>
    <div class="">
        <!--
        <div class="w-full h-50 flex">
            <div class="flex-1">
                <input type="checkbox" id="id_search" value="id" v-model="searchParams.fields">
                <label for="id_search">ID</label>
            </div>
        </div>
        -->
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="items.length || search != ''"  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/user-logs/" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
    </div>
    <div  v-else  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/user-logs/" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
    </div>
</div>
</template>

<script>
import List from '../../../mixins/List'
export default {
    name: 'UserLogsList',
    mixins: [List],
    data: () => ({
        limit: 10,
        page: 0,
        action: '/userlogs',
        searchParams: {
            'model': 'UserLog',
            'fields': ['id'],
            'strict' : false,
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'user',
                label:'User',
                custom: 'displayUser',
            },
            {
                id:'name',
                label:'Name',
            },
            {
                id:'type',
                label:'Type',
            },
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        displayUser(item) {
            return item.user.email
        },
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
    },
    watch: {
        searchParams: {
            handler() {
                this.maybeSearch()
            },
            deep:true,
        },
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
